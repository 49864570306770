import { assign } from 'xstate'
import ApiFetcher from 'lib/api_fetcher'
import {nextStep} from 'utils/step_utils'
import i18n from 'i18n/config'

const stepName  = 'customFieldsStep'

const submitForm = (ctx) => {
  return new Promise((resolve, reject) => {
    ctx.form.submitForm()
      .then(ctx.form.validateForm)
      .then(errors => {
        const isValid = Object.keys(errors).length === 0
        if (isValid) {
          resolve(ctx.form.values)
        } else {
          reject(errors)
        }
      })
  })
}

const initCustomFields = assign({customFields: (ctx, e) => e.data})
const setCustomFieldValues = assign({customFieldValues: (ctx, e) => {
  if (!e.data) {
    return null
  }

  const responses = Object.keys(e.data).map((key) => {
    const custom_field = ctx.customFields.find((cf) => cf.id == key)

    return {
      'id': key,
      'value': e.data[key],
      'position': custom_field.position
    }
  })

  const responsesFiltered = responses
    .filter((response) => response.value != '')
    .sort((a, b) => a.position - b.position)
    .map((response) => ({id: response.id, value: response.value}))

  return responsesFiltered
}})
const updateForm = assign({form: (ctx, e) => e.form})
const setError = (msg) => assign({error: msg})
const cleanError = assign({error: null})

const haveCustomFields = (ctx, e) => e.data && e.data.length > 0

const customFieldsModule = (config) => {
  return {
    initial: 'init',
    states: {
      init: {
        on: {
          '': [
            {
              target: 'waitingCustomFieldsResponses'
            }
          ],
        },
      },
      waitingCustomFieldsResponses: {},
      formValidation: {
        invoke: {
          src: submitForm,
          onDone: {
            target: nextStep(config, stepName) ? `#purchase.${nextStep(config, stepName)}` : '#purchase.reserving',
            actions: [setCustomFieldValues]
          },
          onError: {
            target: 'waitingCustomFieldsResponses',
            actions: [setError(i18n.t('custom_fields_state.invalid_custom_field_response')), 'scrollToError']
          }
        }
      }
    },
    on: {
      FAREHARBOR_FORM_CHANGE: {
        actions: [updateForm, cleanError]
      },
      NEXT_STEP_CLICK: [
        {
          target: '.formValidation'
        }
      ],
      RESERVATION_REQUEST: [
        {
          target: '.formValidation'
        }
      ]
    }
  }
}

export default customFieldsModule
